import config from 'Data';
import React from 'react';
import { compose } from 'recompose';
import { Form, FastField, ErrorMessage, withFormik } from 'formik';
import * as Yup from 'yup';
import Recaptcha from 'react-google-recaptcha';
import { navigate } from 'gatsby';
import { ThemeContext } from 'Common';
import { Container, Row, Col } from 'reactstrap';
import { Text } from 'Theme/styles';

function urlencodeFormData(fd) {
  let s = '';
  function encode(s) {
    return encodeURIComponent(s).replace(/%20/g, '+');
  }
  for (const pair of fd.entries()) {
    if (typeof pair[1] === 'string') {
      s += `${(s ? '&' : '') + encode(pair[0])}=${encode(pair[1])}`;
    }
  }
  return s;
}

const submitForm = event => {
  event.preventDefault();
  const form = document.getElementById('myForm');
  const formAction = form.getAttribute('action');
  const xhr = new XMLHttpRequest();
  xhr.open('post', formAction, true);
  xhr.onload = function (e) {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        const data = JSON.parse(xhr.responseText);
        if (data.redirect) {
          window.location = data.redirect;
        }
      }
    }
  };
  xhr.onerror = function (e) {};
  xhr.send(urlencodeFormData(new FormData(form)));
};

const ContactForm = ({ errors, touched, setFieldValue, isSubmitting }) => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <div>
        <Container className="py-5">
          <Row>
            <Col xs="12" sm="11" md="9" lg="8" className="mx-auto">
              <Text as="p">
                Give is a call at{' '}
                <a href={'tel://' + config.contact.phone} target="_top">
                  {config.contact.phone}
                </a>{' '}
                or fill in the contact form below and we'll get back to you as
                soon as we can.
              </Text>
              <form
                id="myForm"
                action="http://localhost:9000/nutrihealcenter"
                method="post"
                onSubmit={submitForm}
              >
                <input
                  type="hidden"
                  name="formName"
                  className="form-control mb-3"
                  value="Contact us form"
                />
                <input
                  type="hidden"
                  name="formSubject"
                  className="form-control mb-3"
                  value="New Contact Form"
                />
                <input
                  type="hidden"
                  name="marketing_code"
                  className="form-control mb-3"
                  value=""
                />
                <input
                  type="hidden"
                  name="retURL"
                  className="form-control mb-3"
                  value="/thanks/"
                />
                <input
                  type="text"
                  name="first_name"
                  className="form-control mb-3"
                  placeholder="First name"
                  required="required"
                  autoComplete="given-name"
                />
                <input
                  type="text"
                  name="last_name"
                  className="form-control mb-3"
                  placeholder="Last name"
                  required="required"
                  autoComplete="family-name"
                />
                <input
                  type="tel"
                  name="phone"
                  className="form-control mb-3"
                  placeholder="Phone"
                  required="required"
                  autoComplete="tel-national"
                />
                <input
                  type="email"
                  name="email"
                  className="form-control mb-3"
                  placeholder="email@example.com"
                  required="required"
                  autoComplete="home email"
                />
                <button
                  id="submit"
                  className="btn btn-primary px-4"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    )}
  </ThemeContext.Consumer>
);

const enhance = compose(
  withFormik({
    mapPropsToValues: () => ({
      name: '',
      email: '',
      message: '',
      recaptcha: ''
    }),
    validationSchema: () => Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
          .email('Please enter a valid email!')
          .required('Email is required!'),
        message: Yup.string().required('Message is required'),
        recaptcha: Yup.string().required('Robots are not welcome yet!')
      }),
    handleSubmit: async (
      { name, email, message, recaptcha },
      { setSubmitting, resetForm }
    ) => {
      alert('done');
      try {
        const encode = data => {
          return Object.keys(data)
            .map(
              key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
            )
            .join('&');
        };
        await fetch('/?no-cache=1', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'Contact-From',
            name,
            email,
            message,
            'g-recaptcha-response': recaptcha
          })
        });
        setSubmitting(false);
        resetForm();

        navigate('/thanks/');
      } catch (err) {
        setSubmitting(false);
        alert("Something went wrong, please try again!"); // eslint-disable-line
      }
    }
  })
);

export default enhance(ContactForm);
