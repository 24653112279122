import config from 'Data';
import React from 'react';
import { Layout, Head } from 'Common';
import { Hero } from 'Components/ContactPage/Hero';

import ContactForm from 'Components/ContactPage/ContactForm';

const Contact = () => (
  <Layout>
    <Head type="Organization" location="/contact">
      Contact | {config.legalName}
    </Head>
    <Hero />
    <ContactForm />
  </Layout>
);
export default Contact;
